import { inject } from '@angular/core';
import { Route } from '@angular/router';
import { AuthGuard, AuthService } from '@logic-suite/shared/auth';
import { environment } from '../environments/environment';
import { hasAuthorizationGuard } from './shared/authorization/has-authorization.guard';
import { Role } from './shared/authorization/roles.model';
import { CustomerGuard } from './views/customer/customer.guard';
import { NotAuthorizedComponent } from './views/error-pages/not-authorized/not-authorized.component';

export interface AppRoute {
  path: string;
  icon: string;
  label: string;
}

export enum AppRouteTypes {
  Users = 'users',
  Team = 'team',
  Profile = 'profile',
  Neighborhood = 'neighborhood',
  Resource = 'resource',
  Features = 'features',
}

export const appRoutes: Route[] = [
  {
    path: AppRouteTypes.Users,
    loadChildren: () => import('./views/users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard, CustomerGuard, hasAuthorizationGuard([Role.NoovaAdmin, Role.Admin, Role.SuperUser])],
    canLoad: [AuthGuard],
    data: { icon: 'group', label: 'Users', roles: [Role.NoovaAdmin, Role.Admin, Role.SuperUser] },
  },
  {
    path: AppRouteTypes.Team,
    loadChildren: () => import('./views/team/team.module').then(m => m.TeamModule),
    canActivate: [AuthGuard, CustomerGuard, hasAuthorizationGuard([Role.NoovaAdmin, Role.Admin, Role.SuperUser])],
    canLoad: [AuthGuard],
    data: { icon: 'groups', label: 'Team', roles: [Role.NoovaAdmin, Role.Admin, Role.SuperUser] },
  },
  {
    path: AppRouteTypes.Neighborhood,
    loadChildren: () => import('./views/neighborhood/neighborhood.module').then(m => m.NeighborhoodModule),
    canActivate: [AuthGuard, CustomerGuard, hasAuthorizationGuard([Role.NoovaAdmin, Role.Admin, Role.SuperUser])],
    canLoad: [AuthGuard],
    data: { icon: 'token', label: 'Neighborhood', roles: [Role.NoovaAdmin, Role.Admin, Role.SuperUser] },
  },
  {
    path: AppRouteTypes.Resource,
    loadChildren: () => import('./views/resource/resource.module').then(m => m.ResourceModule),
    canActivate: [AuthGuard, CustomerGuard, hasAuthorizationGuard([Role.NoovaAdmin, Role.Admin])],
    canLoad: [AuthGuard],
    data: { icon: 'category', label: 'Resource', roles: [Role.NoovaAdmin, Role.Admin] },
  },
  {
    path: AppRouteTypes.Profile,
    loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard, CustomerGuard, hasAuthorizationGuard([Role.NoovaAdmin])],
    canLoad: [AuthGuard],
    data: { icon: 'next_week', label: 'Company profile', roles: [Role.NoovaAdmin] },
  },
  {
    path: AppRouteTypes.Features,
    loadChildren: () => import('./views/features/features.module').then(m => m.FeaturesModule),
    canActivate: [AuthGuard, CustomerGuard, hasAuthorizationGuard([Role.NoovaAdmin])],
    canLoad: [AuthGuard],
    data: { icon: 'settings', label: 'Features', roles: [Role.NoovaAdmin] },
  },
  {
    path: 'customer',
    canActivate: [AuthGuard],
    canLoad: [AuthGuard],
    loadChildren: () => import('./views/customer/customer.module').then(m => m.CustomerModule),
  },
  {
    path: '403',
    component: NotAuthorizedComponent,
  },
  { path: '**', redirectTo: 'users' },
];

export function getRoutes(): AppRoute[] {
  const auth = inject(AuthService);
  return appRoutes
    .map(r => ({
      path: r.path as string,
      icon: r.data?.icon ?? '',
      label: r.data?.label ?? '',
      isAuthorized: r.data?.roles ? auth.hasRoles(r.data.roles) : true,
    }))
    .filter(r => r.icon != '' && r.isAuthorized)
    .filter(r => !environment.disabledRoutes.includes(r.path));
}
export function getRoute(path: string): AppRoute {
  const route = getRoutes().find(r => r.path === path);
  if (!route) throw new Error(`Route ${path} not found`);
  return route;
}
