import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '@logic-suite/shared/auth';
import { map } from 'rxjs';
import { Role } from './roles.model';

export const hasAuthorizationGuard = (roles: Role[]): CanActivateFn => {
  return () => {
    const auth = inject(AuthService);
    return auth.isLoggedIn$.pipe(map(() => auth.hasRoles(roles)));
  };
};
