import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs';
import { CustomerService } from './customer.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerGuard {
  constructor(
    private customer: CustomerService,
    private router: Router,
  ) {}

  canActivate() {
    return this.customer.getSelectedCustomer().pipe(
      map(cust => {
        if (cust?.customerID != null) return true;
        return this.router.createUrlTree(['/customer']);
      }),
    );
  }
}
