<h1>403</h1>
<h2>{{ 'Not Authorized' | translate }}</h2>

<section>
  {{ 'You are not authorized to use this application' | translate }}.
  {{ 'If you think this is wrong, please contact your company admin, or' | translate }}
  <a href="mailto:post@noova.no">Noova AS</a>
  {{ 'so we can resolve your issue' | translate }}.
</section>

<a mat-raised-button color="primary" [routerLink]="['/']">
  {{ 'Try again' | translate }}
</a>
